.appbar {
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    padding: 0px 24px;

    .appbar-logo {
        min-width: 127px;
    }
    .appbar-search {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
    .appbar-nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 350px;
        padding-left: 50px;
        white-space: nowrap;
    }    
}


.appbar-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 64px;
    color: white;
}
.appbar-input-wrap {
    display: flex;
    align-items: center;
    background-color: rgba(255,255,255,0.1);
    border-radius: 2px;
    padding: 4px;
    position: relative;

    .mui-icons {
        margin-left: 16px;
        margin-right: 16px;
        // position: absolute !important;
        // top: 8px;
    }
    .appbar-input {
        display: inline-block;
        background: none;
        color: white;
        border: 0px solid;
        height: 30px;
        font-size: 16px;
        width: 90%;
        // padding-left: 30px;

        &:focus {
            outline: 0px !important;
        }

        @include placeholder {
            color: white;
        }
    }
}
.appbar-buttons {
    position: absolute;
    right: 84px;
    line-height: 48px;
}