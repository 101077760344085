.status {
    padding: 16px;
    border-radius: 3px;
    
    h5 {
        margin: 0;
        padding: 0;
    }
    &.none {
        background: #A5D6A7; // green200
    }
    &.major {
        background: #D84315; // deepOrange800
        color: #fff;
    }
    &.minor {
        background: #FFF59D; // yellow 200
    }
    &.critical {
        background: #C62828; // red800
        color: #fff;
    }
}