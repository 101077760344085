.flex-columns {
    padding: 0;
    margin: 0;

    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;

    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
}

.flex-item {
    &.flex-0 {
        flex-grow: 0;
    }
    &.flex-1 {
        flex-grow: 1;
    }
    &.flex-2 {
        flex-grow: 2;
    }
    &.flex-3 {
        flex-grow: 3;
    }
    &.flex-4 {
        flex-grow: 4;
    }
    &.flex-5 {
        flex-grow: 5;
    }
    &.flex-6 {
        flex-grow: 6;
    }
    &.flex-7 {
        flex-grow: 7;
    }
    &.flex-8 {
        flex-grow: 8;
    }
    &.flex-9 {
        flex-grow: 9;
    }
    &.flex-10 {
        flex-grow: 10;
    }
}