.mention-menu {
    width: 100%;
    margin-top: 10px;
    border-top: 1px solid $divider-color;

    .mention-item {
        margin: 5px 0;
        padding: 5px;
        font-weight: 300;
        border-radius: 3px;

        &.selected {
            background: $divider-color;
        }
    }
}