.grid-row {
    font-size: $fontsize-small;
    margin-bottom: 16px;
}

.grid-header {
    font-weight: normal;
    font-size: 12px;
    height: 56px;
    line-height: 56px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgb(158, 158, 158);
    position: relative;
    background-color: inherit;
}