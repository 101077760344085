$primary-color: #2196F3;
$primary-color-light: #BBDEFB;
$primary-color-dark: #1976D2;

$secondary-color: lighten($primary-color, 5%);
$success-color: color("green", "base") !default;
$error-color: color("red", "base") !default;
$link-color: color("light-blue", "darken-1") !default;

$accent-color:         #FF5722;
$primary-text-color:   #212121;
$secondary-text-color: #727272;
$divider-color:        #B6B6B6;

$error-text-color: #fff; 

$warning-text-color: #000;
$warning-color: color('amber', 'lighten-2');

$info-text-color: #000;
$info-color: color('light-blue', 'lighten-2');

$success-text-color: #fff;
$success-color: color('green', 'lighten-2');

$fontsize: 1rem !default;
$fontsize-small: 0.8rem !default;
$fontsize-h1: 4.2rem !default;
$fontsize-h2: 3.56rem !default;
$fontsize-h3: 2.92rem !default;
$fontsize-h4: 2.28rem !default;
$fontsize-h5: 1.64rem !default;
$fontsize-h6: 1rem !default;

$photo-item: #DA8AE5;
$article-item: #01BCD4;