.search-filter-bar {
    .type {
        text-transform: uppercase;
        background: #fff;
        color: $primary-text-color;
        display: inline-block;
        padding: 5px;
        cursor: pointer;

        &.selected {
            background: $accent-color;
            color: #fff;
            margin: 0 5px;
        }
    }
}
.media-result {
    &.grid {
        position: relative;
        font-size: 11px;
        cursor: pointer;

        .type {
            position: absolute;
            top: 0;
            left: 0;
            background: $primary-color-dark;
            color: #fff;
            padding: 2px 5px;
        }

        a {
            display: block;
        }
        .date {
            color: $primary-text-color;
            display: block;
        }
        .grid-tile:hover img {
            opacity: .80;
        }
    }
    &.list {
        font-size: 16px;
        cursor: pointer;
        line-height: 1.4em;

        border-bottom: 1px solid $divider-color;
        margin-bottom: 10px;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0px;
        }

        .type {
            background: $primary-color-dark;
            color: #fff;
            padding: 2px 5px;
        }

        a {
            display: block;
        }
        .date {
            color: $primary-text-color;
            display: block;
        }

    }
}

.react-resizable-handle {
    background-color: #fff;
}
.richEditor-media {

    transition: all .3s;

    border-radius: 2px;

    background: #fff;

    margin: 0;
    padding: 10px;

    position: fixed;
    top: 5%;
    left: 0;

    z-index: 9999;

    width: 60%;
    height: 80%;

    .buttonBar {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
        width: 100%;
    }

    .contentPreview {
        padding: 10px;
    }

    .searchForm {
        padding: 10px;
    }

    .mediaEditContainer {
        position: relative;

        .propertyBox {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    .richEditor-mediaResults {
        overflow: scroll;
    }

    .richEditor-mediaResult {
        img {
            max-width: 100%;
        }

        cursor: pointer;
    }
}
    figure {
        // margin: 0;
        // padding: 0;

        box-sizing: border-box;
        display: block ;
        margin: 0px ;

        .alignment-container {
            margin: 0;

            &.left {
                float: left;
                margin-top: 10px;
                margin-bottom: 10px;
                margin-right: 10px;
            }
            &.right {
                float: right;
                margin-top: 10px;
                margin-bottom: 10px;
                margin-left: 10px;
            }
            &.center {
                margin: auto;
                margin-top: 10px;
                margin-bottom: 10px;
                text-align: center;
                display: block;
            }

            .alignment-inner {
                position: relative;
                z-index: 1;

                .alignment-content {
                    display: inline-block;
                    line-height: 0px;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    position: relative;
                    vertical-align: text-bottom;

                    .center-toolbar {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        z-index: 10;
                        text-align: center;
                        width: 100%;

                        button {
                            background: #333;
                            color: #fff;

                            &:hover {
                                background: #666;
                            }
                        }
                    }

                    .top-toolbar {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        text-align: center;
                        background: #000;

                        opacity: 0.6;

                        transition: all .3s;

                        &:hover {
                            opacity: 1;
                        }

                        button {

                        }

                        i {
                            display: inline-block;
                            cursor: pointer;
                            padding: 5px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

.media-type {
    background: $primary-color-dark;
    color: #fff;
    padding: 2px 5px;
}