.delete-button-root {
    display: inline-block;
    position: relative;
    height: 36px;
    margin: 10px 24px;

    &.full {
        margin: 0px;
        width: 100%;
    }
    
    [mode="determinate"] {
        position: absolute !important;
        bottom: 0;
        left: 0;
        background-color: #B71C1C !important;
        
        & > div {
            background: #fff !important;
        };
    }
}