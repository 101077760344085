.dropzone-root {

    .dropzone-container {

        transition: all .3s;
        border: 4px dashed #fff;

        .dropzone-file {
            img {
                max-width: 100%;
            }

            i {
                font-size: 80px;
            }

            strong {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .dropzone-loader {
            text-align: center;
            width: 100%;

            strong {
                display: block;
                margin-top: 20px;
            }
        }

        .dropzone-inner-content {
            min-height: 200px;
            overflow: auto;
        }

        &.active {
            background: #f1f1f1;
            border-color: #999;
        }
    }

    .dropzone-default {
        width: 100%;

        text-align: center;
        h4 {
            margin: auto;
            text-align: center;
            padding-top: 40px;
        }
    }
}