form {
    .input-block {
        width: 100%;
        margin-bottom: 10px;
    }
}

.prefix {
    background: #f1f1f1;
    border-radius: 3px 0 0 3px;
    color: $secondary-text-color;
    padding: 10px;
}

.fixed-label {
    line-height: 22px;
    font-size: 0.8em;
    color: rgba(33, 33, 33, 0.498039);
    -webkit-user-select: none;
}