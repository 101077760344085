body {
    background: #f1f1f1;
    margin: 0;
    padding: 0;
}

hr {
    background: inherit;
    border: 0;
}

#app-container {

    .main-container {
        margin: 0;
        padding: 0;
        position: relative;
        transform: all 450ms ease-out;

        .main-container-inner {
            margin: 16px;
        }
    }
}

.assignment-root,
.assignment-item-root, 
.content-root, 
.content-item-root,
.containers-root,
.container-item-root,
.search-root,
.tag-root,
.issue-root,
.author-root {
    > .row > div.col-xs-12:first-child {
        position: relative;
        width: 100%;
        margin: 0 auto;
    }
}
/*
 * leave item-roots out of this so they take full width at this breakpoint 
 */
@media only screen and (min-width: 1146px) and (max-width: 1555px)  {
    .assignment-root, 
    .content-root, 
    .containers-root,
    .tag-root,
    .issue-root,
    .author-root {
        > .row > div.col-xs-12:first-child {
            max-width: 75%;
            margin-left: 140px;
            padding: 0px;
        }
    }
}
@media only screen and (min-width: 1556px) and (max-width: 1831px) {
    .assignment-root,
    .assignment-item-root, 
    .content-root, 
    .content-item-root,
    .containers-root,
    .container-item-root,
    .search-root,
    .tag-root,
    .issue-root,
    .author-root {
        > .row > div.col-xs-12:first-child {
            max-width: 1200px;
            margin-left: 140px;
            padding: 0px;
        }
    }
}
@media only screen and (min-width: 1832px) {
    .assignment-root,
    .assignment-item-root, 
    .content-root, 
    .content-item-root,
    .containers-root,
    .container-item-root,
    .search-root,
    .tag-root,
    .issue-root,
    .author-root {
        > .row > div.col-xs-12:first-child {
            max-width: 1200px;
            left: -140px;
            padding: 0px;
        }
    }
}

/*
 * override flexbox grid:
 * because you want something centered doesn't 
 * mean you want the text inside it centered
 */
.center-xs {
    text-align: left;
}

.padded {
    padding: 16px;
}
.clear-bottom {
    margin-bottom: 16px;
}
.clear-top {
    margin-top: 16px;
}
.shadow {
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
}