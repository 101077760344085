.pill {
    border-radius: 3px;
    background: #e1e1e1;
    padding: 5px;
    display: inline-block;
    font-size: 15px;
    margin-right: 10px;

    &:last-child {
        margin-right: 0;
    }

    .mui-icons, .fa {
        cursor: pointer;
        font-size: 1.2em !important;
        top: 4px !important;
    }
}