.align-left {
    text-align: left !important;
}
.align-center {
    text-align: center;
}
.align-right {
    text-align: right !important;
}
.align-right, .align-left, .align-center {
    .public-DraftStyleDefault-ltr {
        text-align: inherit;
    }
}