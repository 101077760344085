#app-splash {
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: #fff;
    padding-top: 15%;

    transition: all 0.5s ease-in;

    &.end {
        opacity: 0;
    }

    img {
        max-width: 40%;
        margin: auto;
        display: block;
    }

    span {
        display: block;
        font-size: 1.2em;
        margin-top: 1em;
    }
}