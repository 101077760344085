.pagination {

    li {
        list-style-type: none;
        display: inline-block;
        padding: 8px 4px;
        margin: 0 4px;
        vertical-align: middle;

        a, span {
            color: #000;
            text-decoration: none;
        }
        &.disabled {
            a, span {
                color: #f1f1f1;
            }
        }
        &.active {
            a, span {
                color: #fff;
            }
            background: $accent-color;
        }
        &:last-child {
            margin-right: 0;
        }        
   }
}