.content-list-card {
    margin-bottom: 10px;
    padding: 10px;
    border-top: 5px solid #9B9B9B;
    font-size: 12px;

    border-radius: 3px;

    &.published {
        border-color: #4CAF50;
    }
    &.scheduled {
        border-color: #03A9F4;
    }
    &.zoom {
        font-size: 16px;

        h3 > a {
            font-size: 16px;
        }
    }

    h3 {
        margin: 0;
        padding: 0;
        color: #000;
        margin-bottom: 5px;

        a {
            color: #000;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
        }
        span {
            opacity: 0.001;
        }
    }
    .lock-status {
        font-size: 16px !important;
        position: relative;
        top: 2px;
        color: $accent-color !important;
    }
    .meta {
        margin-top: 32px;

        .value {
            display: inline-block;

            &:last-child {
                border-right: 0;
                margin-right: 0;
                padding-right: 0;
            }

            border-right: 1px solid #333;
            margin-right: 10px;
            padding-right: 10px;

            font-weight: 300;
            strong {
                font-weight: 500;
            }
        }
    }
    .time {
        font-weight: 500;
        line-height: 1.5em;

        span {
            display: inline-block;
            width: 85px;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 0.9em;
        }
    }
}