.toolbar {
    padding: 10px;
    margin-bottom: 16px;
    background: #fff;
    
    .pagination {
        margin: 0;
        padding: 0;

        li {
            padding: 8px 4px;
            margin: 0 4px;
            vertical-align: middle;

            a {
                color: #000;
                text-decoration: none;
            }
            &.disabled {
                a {
                    color: #f1f1f1;
                }
            }
            &.active {
                a {
                    color: #fff;
                }
                background: $accent-color;
            }
            &:last-child {
                margin-right: 0;
            }

        }
    }

    .toolbar-search-wrap {
        display: flex;
        align-items: center;
        background-color: rgba(0,0,0,0.07);
        border-radius: 2px;
        padding: 4px;

        .mui-icons {
            color: black;
            margin-left: 16px;
            margin-right: 16px;        
        }
        .search {
            display: inline-block;
            background: none;
            color: black;
            border: 0px solid;
            height: 30px;
            font-size: 16px;

            &:focus {
                outline: 0px !important;
            }

            @include placeholder {
                color: black;
            }
        }    
    }    

    .lock-indicator {
        color: $secondary-text-color;
        text-transform: uppercase;
        .mui-icons {
            position: relative;
            top: 2px;
            color: $secondary-text-color !important;
        }
    }
}