.card-header {
    @extend .padded;
    @extend .clear-bottom;

    background: $primary-color;
    color: #fff;

    &.issue {
    	background: none;
    	color: $primary-text-color;
    	font-weight: 700;
    	margin-bottom: 0px;
    }
}

.assignment {
    margin-bottom: 16px;

	.assignment-item {
        margin-right: -16px;
        margin-left: -16px;
		padding: 16px;

		&.photo {
			border-left: 8px solid $photo-item;

            &:hover {
                background-color: lighten($photo-item, 20%);
            }            
		}		
		&.article {
			border-left: 8px solid $article-item;

            &:hover {
                background-color: lighten($article-item, 50%)
            }
		}
	}
}