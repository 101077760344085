.simple-editor-root {
    border: 1px solid lighten($divider-color, 15%);
    border-radius: 3px;
    padding: 16px;
    margin-bottom: 16px;

    .simple-editor-toolbar {
        padding-bottom: 16px;

        .simple-editor-styleButton {
            padding: 4px 4px;
            padding-bottom: 1px;
            border-radius: 3px;
            background: darken(white, 2%);
            margin-right: 6px;
            font-size: 1rem;
            line-height: 1rem;

            &:last-child {
                margin-right: 0;
            }

            &:hover, &.simple-editor-activeButton {
                background: darken(white, 10%);
            }
        }

        .simple-editor-controls {
            display: inline-block;
            cursor: pointer;
        }
    }

    .simple-editor-editor {
        font-size: 1.2rem;

        &.with-toolbar {
            border-top: 1px solid lighten($divider-color, 15%);
            padding: 16px 0;
        }

        .public-DraftStyleDefault-block {
            margin-bottom: 1em;
        }

    }

    .mention {
        color: $primary-color-dark;
    }

    .HorizontalRuleBlock {
        background: #333;
        border-bottom: 1px solid #333;
    }
}