#login-root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    .login-wrap {
        margin: 1em;
        flex: 1;

        .login-row {
            min-height: 90vh;
        }
    }

    .login-logo {
        img {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    footer {
        @extend .quiet;
        text-align: center;
        font-size: $fontsize-small;
    }

    input {
        font-size: $fontsize;
        padding: 16px 10px;
        padding-right: 0;
        border-radius: 3px;
        border: 1px solid lighten($secondary-text-color, 40%);
        max-width: 100%;

        &[type=submit] {
            display: block;
            width: 50%;
            margin: auto;
            padding: 16px 10px;
            text-align: center;
            background-color: $primary-color;
            color: white;
            border: 0px solid;
        }
    }

}