@mixin richEditor-Modal-root($baseColor, $activeColor, $width:200px) {

    box-shadow: -3px 3px 4px rgba(0, 0, 0, 0.15);

    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: $width;
    padding: 10px;

    background: $baseColor;

    transition: all 300ms;

    a {
        color: #000;
    }

    &:hover {
        box-shadow: -4px 3px 4px rgba(0, 0, 0, 0.20);
        &:after {
            text-shadow: -4px 3px 4px rgba(0,0,0,0.20);
        }
    }

    &:after {
        content: "◀";
        display: inline-block;
        transform: scaleY(1.5);
        color: $baseColor;
        text-shadow: -3px 3px 4px rgba(0,0,0,.15);
        font-size: 32px;

        position: absolute;
        top: 0;
        left: -24px;

        transition: text-shadow .3s;
    }

}

.richEditor-linkModal-root {
    @include richEditor-Modal-root(color('cyan', 'lighten-5'), color('cyan', 'lighten-3'));
}

.richEditor-copyNoteView {
    position: relative;
    background: #fff;
    padding: 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-bottom: 10px;
    left: 0;
    width: 90%;

    transition: all 300ms ease-out;

    &.selected {
        box-shadow: -1px 1px 4px rgba(0,0,0,0.35);
        left: -20%;
        width: 110%;
    }

    .more-indicator {
        text-align: center;
        display: block;
        font-size: 11px;
        color: $secondary-text-color;

        i:first-child {
            padding-right: 10px;
        }
        i:last-child {
            padding-left: 10px;
        }
    }
}
.richEditor-singleComment {
    font-size: 14px;

    strong {
        font-size: 0.9em;
    }
    .meta {
        font-size: 0.8em;
        color: $secondary-text-color;
    }

    border-bottom: 1px solid $divider-color;
    margin-bottom: 20px;

    &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
    }
}