.toolbar-dropdown {

    &.closed {
        height: 0;
        overflow: hidden;
    }
    &.open {
        margin-top: 10px;
        border-top: 1px solid #bababa;
        height: 100%;
        overflow: visible;
    }

    .dropdown-box {
        max-height: 400px;
        overflow: auto;
    }
}