.text-field {
    font-size: 0.8em;
    line-height: 1.5em;
    padding: 2px;
    width: 100%;

    border: 1px solid #ccc;
    border-radius: 3px;

    transition: all 300ms ease-out;

    &:focus {
        outline: 0;
        border-color: $primary-color;
    }
}