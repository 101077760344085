// .RichEditor-pullQuote {
//     @extend .z-depth-1;

//     background: #fff;
//     padding: 10px;

//     p {
//         font-size: 1.2em;
//         text-align: center;

//         strong {
//             font-size: 0.8em;
//             display: block;
//             text-align: right;
//         }
//     }

//     textarea {
//         width: 100%;
//         height: 100px;
//         border: 1px solid #f1f1f1;

//         &:focus {
//             outline: 0;
//         }
//     }

// }

.pullquote-quote {
    font-size: 18px;
    font-style: italic;

    &:before {
        content: '\201C'
    }
    &:after {
        content: '\201D'
    }
}

.pullquote-credit {
    margin-top: 10px;
}