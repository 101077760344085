.user-search-menu-root {
    position: relative;
    z-index: 2;

    .user-search-menu {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        overflow: auto;
        padding: 10px;
        border: 1px solid lighten($divider-color, 25%);
        border-radius: 3px;
        background: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        z-index: 2;

        & > div {
            font-size: 1rem;
            height: 1.5rem;
            line-height: 1.5rem;
            padding: 6px;
            transition: all 100ms ease-out;
        }

        .highlighted {
            background: $primary-color;
            color: #fff;
            border-radius: 2px;
        }
    }
}