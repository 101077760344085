.state-pill {
    display: inline-block;
    padding: 2px 6px;
    margin: 0 4px;
    background: $primary-color-light;
    border-radius: 2px;
    font-size: 12px;
    cursor: pointer;

    &.active {
        background: $primary-color-dark;
        color: #fff;
    }
}