@charset "UTF-8";
/*
 * Flexbox grid settings
 */
$grid-columns: 12;

$gutter-width: 1em;
$outer-margin: 1em;

$breakpoints:
  sm 38em 38rem,
  md 52em 52rem,
  lg 75em 71rem,
  xl 120rem 75rem;

/**
 * Vendor css files
 */
@import "../vendor/flexboxgrid-sass/flexboxgrid.scss";
@import "../../node_modules/draft-js/dist/Draft.css";
@import "../../node_modules/codemirror/lib/codemirror.css";
@import "../../node_modules/react-resizable/css/styles.css";
@import "../../node_modules/react-anything-sortable/sortable.css";

/**
 * Icons!
 */
@import "./modules/font-awesome";
@import "./modules/material-icons";

/**
 * CEO Modules
 */

@import "./modules/variables";
@import "./modules/mixins";
@import "./modules/typography";
@import "./modules/layout";
@import "./modules/delete-button";
@import "./modules/attachment-edit-bar";
@import "./modules/user-search-box";
@import "./modules/author-search-box";
@import "./modules/tag-search-box";
@import "./modules/sortable";
@import "./modules/status-error-indicators";
@import "./modules/expanding-card";
@import "./modules/flex-column";
@import "./modules/darkroom";
@import "./modules/app-splash";
@import "./modules/state-pill";

@import "./modules/pagination";
@import "./modules/forms";
@import "./modules/login";
@import "./modules/footer";
@import "./modules/quips";
@import "./modules/splashy";
@import "./modules/rich-editor";
@import "./modules/simple-editor";
@import "./modules/col-grid";
@import "./modules/pill";
@import "./modules/mention-menu";
@import "./modules/dropzone";
@import "./modules/ceo-appbar";
@import "./modules/url-handler";

/**
 * Finally, the site theme
 */
@import "./theme/theme";

// @import "./modules/main-view";
// @import "./modules/buttons";
// @import "./modules/dropdown";
// @import "./modules/errors";
// @import "./modules/animations";
// @import "./modules/diff";

// @import "./modules/debug";

// @import "./modules/form-global-search";

// @import "./modules/header";
// @import "./modules/nav";
// @import "./modules/preloader";
