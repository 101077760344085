.editor-container {
    border: 1px solid lighten($divider-color, 15%);
    border-radius: 3px;
    padding: 16px;
    margin-bottom: 2em;
    margin-top: 2em;

    .editor-divider {
        text-align: center;
        width: 100%;
        font-size: 0.8rem;
        color: $divider-color;
        text-transform: uppercase;
    }

    .restore-draft-button {
        font-size: 0.8em;
    }
    .public-DraftStyleDefault-block {
        margin-bottom: 1em;
    }
}

.richEditor-root {

    // label {
    //     display: block;
    // }

    .richEditor-toolbar {
        padding-bottom: 16px;

        .richEditor-styleButton {
            padding: 4px 4px;
            padding-bottom: 1px;
            border-radius: 3px;
            background: darken(white, 2%);
            margin-right: 6px;
            font-size: 1rem;
            line-height: 1rem;

            &:hover, &.richEditor-activeButton {
                background: darken(white, 10%);
            }
        }

        .richEditor-controls {
            display: inline-block;
            cursor: pointer;
            
            &:first-child .richEditor-styleButton {
                font-size: .8rem;
                line-height: 1rem;
                padding-bottom: 5px;
                vertical-align: top;            
            }
        }
    }

    .public-DraftEditor-content {
        h1:first-child {
            margin-top: .5em;
        }
    }

    .richEditor-editor {
        border-top: 1px solid lighten($divider-color, 15%);
        padding: 16px 0;
        font-size: 1.2rem;
    }

    .copyNoteMarker {
        background: #FFF9C4;
        border-radius: 3px;
        padding: 0 2px;
    }

    .inlineInput {
        min-width: 25%;
        display: inline-block;
        margin: 0 5px;
        padding: 4px 4px;

        background: #BBDEFB;

        input {
            border: none;
            box-shadow: none;
            margin-bottom: 2px;
            height: inherit;
            display: inline;
            width: inherit;
            color: $primary-text-color;

            &:focus {
                border: none;
                box-shadow: none;
            }
        }
    }

    .inlineLink {
        color: $primary-color;
    }
}

// .editor-notifier {
//     font-size: 12px;
// }

.richEditor-copyQuote {
    background: #BBDEFB;
}

@import "./rich-editor/modals";
@import "./rich-editor/pullQuote";
@import "./rich-editor/embed";
@import "./rich-editor/media";
@import "./rich-editor/dropcap";
@import "./rich-editor/hr";
@import "./rich-editor/align";
