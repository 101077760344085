.quip {
    background: lighten($secondary-text-color, 25%);

    padding: 10px;
    border-radius: 3px;
    transition: all .2s;

    text-align: center;
    color: #333;

    span {
        transition: opacity .3s;

        a {
            color: #fff;
        }

        &.fader {
            opacity: 0;
        }
    }
}
